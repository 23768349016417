/**
 * This file was created automatically. Do not edit it manually.
 * @example npm run paths
 */
export const PATHS = {
  index: '/',
  notFound: '/*',
  manager: {
    index: '/head',
    vacations: {
      index: '/head/vacations',
      year: '/head/vacations/:year',
      schedule: '/head/vacations/schedule',
      applicationForm: '/head/vacations/application-form',
      detail: {
        index: '/head/vacations/detail',
        applicationId: '/head/vacations/detail/:applicationId',
      },
      allVacationsSchedule: {
        index: '/head/vacations/all-vacations-schedule',
        year: {
          index: '/head/vacations/all-vacations-schedule/:year',
          vacationScheduleId: '/head/vacations/all-vacations-schedule/:year/:vacationScheduleId',
        },
      },
    },
    benefits: {
      index: '/head/benefits',
      create: '/head/benefits/:benefitId',
      application: {
        index: '/head/benefits/application',
        id: '/head/benefits/application/:applicationId',
      },
    },
  },
  hr: {
    index: '/hr',
    vacations: {
      index: '/hr/vacations',
      year: '/hr/vacations/:year',
      schedule: '/hr/vacations/schedule',
      applicationForm: '/hr/vacations/application-form',
      detail: {
        index: '/hr/vacations/detail',
        applicationId: '/hr/vacations/detail/:applicationId',
      },
      allVacationsSchedule: {
        index: '/hr/vacations/all-vacations-schedule',
        year: {
          index: '/hr/vacations/all-vacations-schedule/:year',
          division: {
            index: '/hr/vacations/all-vacations-schedule/:year/:division',
            vacationScheduleId: '/hr/vacations/all-vacations-schedule/:year/:division/:vacationScheduleId',
          },
        },
      },
    },
    benefits: {
      index: '/hr/benefits',
      create: '/hr/benefits/:benefitId',
      application: {
        index: '/hr/benefits/application',
        id: '/hr/benefits/application/:applicationId',
      },
      management: {
        index: '/hr/benefits/management',
        create: '/hr/benefits/management/create',
        benefit: {
          index: '/hr/benefits/management/:benefitId',
        },
      },
    },
    reports: {
      index: '/hr/reports',
    },
  },
  employee: {
    index: '/employee',
    benefits: {
      index: '/employee/benefits',
      create: '/employee/benefits/:benefitId',
      application: {
        index: '/employee/benefits/application',
        id: '/employee/benefits/application/:applicationId',
      },
    },
    vacations: {
      index: '/employee/vacations',
      year: '/employee/vacations/:year',
      applicationForm: '/employee/vacations/application-form',
      detail: {
        index: '/employee/vacations/detail',
        applicationId: '/employee/vacations/detail/:applicationId',
      },
    },
  },
} as const
