import { Box, Combobox, InputProps, useCombobox, useMantineTheme } from '@mantine/core'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import { VacationApplicationCategory, VacationApplicationType } from 'src/shared/types/swagger/api.dto'
import { BaseInputWithFloatedLabel } from 'src/shared/ui-kit/base-input-with-floated-label'
import { Icon } from 'src/shared/ui-kit/icon'

import { VACATION_TYPE_TRANSLATION_KEY_MAPPER } from '../lib/constants'
import { filterVacationTypeOptions } from '../lib/utils'

type VacationTypeSelectProps = Pick<InputProps, 'required' | 'size' | 'error' | 'disabled'> &
  Partial<{
    value: VacationApplicationType
    onChange: (v: VacationApplicationType) => void
  }> & {
    vacationCategoryValue: VacationApplicationCategory | null
  }

export const VacationTypeSelect = forwardRef<HTMLButtonElement, VacationTypeSelectProps>(function VacationTypeSelect(
  { required, size, value, onChange, error, disabled, vacationCategoryValue },
  ref,
) {
  const combobox = useCombobox()
  const { t } = useTranslation()
  const theme = useMantineTheme()

  const filteredOptions = Object.values(VacationApplicationType).filter((vacationType) =>
    filterVacationTypeOptions(vacationCategoryValue, vacationType),
  )

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      // проблемы с типизацией библиотеки
      // @ts-ignore
      onOptionSubmit={(val: VacationApplicationType) => {
        onChange?.(val)
        combobox.closeDropdown()
      }}
      styles={{
        dropdown: {
          top: size === 'lg' ? 24 : 0,
          right: 0,
          transform: `translateY(${theme.other.input.md})`,
        },
      }}
    >
      <Box pos="relative">
        <Combobox.Target>
          <BaseInputWithFloatedLabel
            ref={ref}
            size={size}
            required={required}
            disabled={disabled}
            error={error}
            label={t('vacations.applications.vacation-type.label')}
            rightSection={
              <Icon name={`chevron_${combobox.dropdownOpened ? 'up' : 'down'}`} fill={theme.colors['light'][6]} />
            }
            onClick={() => combobox.toggleDropdown()}
            value={value ? t(VACATION_TYPE_TRANSLATION_KEY_MAPPER[value]) : ''}
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {filteredOptions.map((el) => (
              <Combobox.Option value={el} key={el}>
                {t(VACATION_TYPE_TRANSLATION_KEY_MAPPER[el])}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Box>
    </Combobox>
  )
})
