import { InputBase, InputBaseProps, Text } from '@mantine/core'
import { ComponentProps, forwardRef, useState } from 'react'

import classes from './base-input-with-floated-label.module.css'

type BaseInputWithFloatedLabelProps = Pick<
  InputBaseProps,
  'label' | 'rightSection' | 'size' | 'required' | 'error' | 'disabled' | 'w'
> &
  Pick<ComponentProps<'button'>, 'onClick' | 'value'>

export const BaseInputWithFloatedLabel = forwardRef<HTMLButtonElement, BaseInputWithFloatedLabelProps>(function Input(
  { size = 'md', ...rest },
  ref,
) {
  const [focused, setFocused] = useState<boolean>(false)

  const floating = focused || !!rest.value || undefined

  return (
    <InputBase
      ref={ref}
      component="button"
      type="button"
      pointer
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      rightSectionPointerEvents="none"
      classNames={{
        label: classes.label,
        root: classes.root,
        input: classes.input,
        error: classes.error,
      }}
      wrapperProps={{
        'data-floating': floating,
        'data-size': size,
      }}
      {...rest}
    >
      {rest.value ? (
        <Text size="md" lineClamp={1}>
          {rest.value}
        </Text>
      ) : null}
    </InputBase>
  )
})
