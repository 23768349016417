import {
  CheckIcon,
  Combobox,
  ComboboxProps,
  Group,
  Pill,
  PillsInput,
  PillsInputProps,
  useCombobox,
  useMantineTheme,
} from '@mantine/core'
import { ReactElement, useState } from 'react'

import { IdentifiableWithName } from 'src/shared/types'
import { Icon } from 'src/shared/ui-kit/icon'

import classes from './multi-select-with-floated-label.module.css'

export type MultiSelectWithFloatedLabelProps = {
  data: IdentifiableWithName[]
  value?: number[]
  label: string
  onChange?: (value: number[]) => void
  size: 'lg'
} & Pick<ComboboxProps, 'withinPortal'> &
  Pick<PillsInputProps, 'required' | 'error'>

export const MultiSelectWithFloatedLabel = ({
  data,
  label,
  value = [],
  size,
  onChange,
  withinPortal = false,
  required,
  error,
}: MultiSelectWithFloatedLabelProps): ReactElement => {
  const theme = useMantineTheme()
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  })

  const [search, setSearch] = useState<string>('')
  const [focused, setFocused] = useState<boolean>(false)

  const floating = focused || !!value.length || undefined

  const handleValueSelect = (val: string): void => {
    const newValue = Number(val)
    onChange?.(value.includes(newValue) ? value.filter((v) => v !== newValue) : [...value, newValue])
  }

  const handleValueRemove = (val: number) => {
    onChange?.(value.filter((v) => v !== val))
  }

  const values = data
    .filter((item) => value.includes(item.id))
    .map((item) => (
      <Pill size="sm" key={item.id} withRemoveButton onRemove={() => handleValueRemove(item.id)}>
        {item.name}
      </Pill>
    ))

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={withinPortal}>
      <Combobox.DropdownTarget>
        <PillsInput
          size={size}
          label={label}
          classNames={{
            label: classes.label,
            root: classes.root,
            input: classes.input,
            error: classes.error,
          }}
          wrapperProps={{
            'data-floating': floating,
            'data-size': size,
          }}
          rightSectionPointerEvents="none"
          rightSection={
            <Icon name={`chevron_${combobox.dropdownOpened ? 'up' : 'down'}`} fill={theme.colors['light'][6]} />
          }
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onClick={() => combobox.openDropdown()}
          required={required}
          error={error}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex()
                  setSearch(event.currentTarget.value)
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault()
                    handleValueRemove(value[value.length - 1])
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options mah={200} style={{ overflowY: 'auto' }}>
          {data.map((item) => (
            <Combobox.Option value={item.id as unknown as string} key={item.id} active={value.includes(item.id)}>
              <Group gap="sm" justify="space-between">
                <Group gap={4}>
                  <span>{item.name}</span>
                </Group>

                {value.includes(item.id) ? <CheckIcon size={12} color={theme.colors.light[6]} /> : null}
              </Group>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}
