import { createBrowserRouter, Navigate, Outlet, Params } from 'react-router-dom'

import { RootEmployeeApplicationDetailPage } from 'src/pages/employee-application-detail-page'
import { RootHeadApplicationDetailPage } from 'src/pages/head-application-detail-page'
import { RootHrApplicationDetailPage } from 'src/pages/hr-application-detail-page'
import { HrReportsPage } from 'src/pages/hr-reports-page'
import { BreadcrumbLink } from 'src/widgets/breadcrumbs'
import { Layout } from 'src/widgets/layout'
import {
  benefitApplicationDetailLoader,
  benefitDetailLoader,
  companiesLoader,
  selectedEmployeeScheduleLoader,
} from 'src/shared/api'
import { PATHS, ROUTES } from 'src/shared/constants'
import { queryClient } from 'src/shared/providers'
import { RoleGuard } from 'src/shared/providers/role-provider/ui/role-guard'
import {
  Benefit,
  BenefitApplication,
  CompanySchema,
  EmployeeVacationScheduleWithChangeRecord,
  Role,
} from 'src/shared/types/swagger/api.dto'

export const router = createBrowserRouter([
  {
    path: ROUTES.index,
    element: <Layout />,
    // TODO: согласовать страницу ErrorBoundary
    errorElement: <Layout withErrorNotification />,
    children: [
      {
        index: true,
        lazy: async () => {
          const { HomePage } = await import('src/pages/home-page')
          return { Component: HomePage }
        },
      },

      {
        path: ROUTES.employee.index,
        element: <RoleGuard roles={[Role.Employee]} fallback={<Navigate to={PATHS.index} />} />,
        handle: {
          crumb: () => <BreadcrumbLink to={PATHS.employee.index} name="common.home" />,
        },
        children: [
          {
            index: true,
            lazy: async () => {
              const { EmployeeHomePage } = await import('src/pages/employee-home-page')
              return { Component: EmployeeHomePage }
            },
          },
          {
            path: PATHS.employee.benefits.index,
            element: <Outlet />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.employee.benefits.index} name="common.benefits" />,
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { BenefitsPage } = await import('src/pages/benefits-page')
                  return { Component: BenefitsPage }
                },
              },
              {
                path: PATHS.employee.benefits.create,
                handle: {
                  crumb: (data: Benefit) => (
                    <BreadcrumbLink to={`${PATHS.employee.benefits.index}/${data.id}`} name={data.name} />
                  ),
                },
                lazy: async () => {
                  const { BenefitFormPage } = await import('src/pages/benefit-form-page')
                  return { Component: BenefitFormPage }
                },
                loader: benefitDetailLoader(queryClient),
              },
              {
                path: ROUTES.employee.benefits.application.index,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={PATHS.employee.benefits.index} />,
                  },
                  {
                    path: PATHS.employee.benefits.application.id,
                    handle: {
                      crumb: (data: BenefitApplication, _pathName: string, params: Params<'applicationId'>) => (
                        <BreadcrumbLink
                          to={`${PATHS.employee.benefits.application.index}/${params.applicationId}`}
                          name={data.author?.name}
                        />
                      ),
                    },
                    lazy: async () => {
                      const { BenefitsApplicationPage } = await import('src/pages/benefits-application-page')
                      return { Component: BenefitsApplicationPage }
                    },
                    loader: benefitApplicationDetailLoader(queryClient),
                  },
                ],
              },
            ],
          },
          {
            path: PATHS.employee.vacations.index,
            element: <Outlet />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.employee.vacations.index} name="common.vacations" />,
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { EmployeeVacationsPage } = await import('src/pages/employee-vacations-page')
                  return { Component: EmployeeVacationsPage }
                },
              },
              {
                path: ROUTES.employee.vacations.applicationForm,
                lazy: async () => {
                  const { EmployeeApplicationFormPage } = await import('src/pages/employee-application-form-page')
                  return { Component: EmployeeApplicationFormPage }
                },
                handle: {
                  crumb: () => (
                    <BreadcrumbLink to={PATHS.employee.vacations.applicationForm} name="vacations.applications.form" />
                  ),
                },
              },
              {
                path: ROUTES.employee.vacations.detail.index,
                element: <RootEmployeeApplicationDetailPage />,
                children: [
                  {
                    path: ROUTES.employee.vacations.detail.applicationId,
                    lazy: async () => {
                      const { EmployeeApplicationDetailPage } = await import(
                        'src/pages/employee-application-detail-page'
                      )
                      return { Component: EmployeeApplicationDetailPage }
                    },
                    handle: {
                      crumb: () => (
                        // отказались от динамических хлебных крошек
                        // https://tekmates.slack.com/archives/C079LT06248/p1725869007124049?thread_ts=1725864972.701569&cid=C079LT06248
                        <BreadcrumbLink to={PATHS.employee.index} name="common.employee-application" end={false} />
                      ),
                    },
                  },
                ],
              },
              {
                path: ROUTES.employee.vacations.year,
                lazy: async () => {
                  const { EmployeeVacationSchedulePage } = await import('src/pages/employee-vacation-schedule-page')
                  return { Component: EmployeeVacationSchedulePage }
                },
                handle: {
                  crumb: (_data: unknown, _pathName: string, params: Params<'year'>) => (
                    <BreadcrumbLink
                      to={`${PATHS.employee.vacations.index}/${params.year}`}
                      name="vacations.vacation-schedule"
                    />
                  ),
                },
              },
            ],
          },
        ],
      },

      {
        path: ROUTES.hr.index,
        element: <RoleGuard roles={[Role.Hr]} fallback={<Navigate to={PATHS.index} />} />,
        handle: {
          crumb: () => <BreadcrumbLink to={PATHS.hr.index} name="common.home" />,
        },
        children: [
          {
            index: true,
            lazy: async () => {
              const { HrHomePage } = await import('src/pages/hr-home-page')
              return { Component: HrHomePage }
            },
          },
          {
            path: PATHS.hr.vacations.index,
            element: <Outlet />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.hr.vacations.index} name="common.vacations" />,
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { HrVacationsPage } = await import('src/pages/hr-vacations-page')
                  return { Component: HrVacationsPage }
                },
              },
              {
                path: ROUTES.hr.vacations.applicationForm,
                lazy: async () => {
                  const { HrApplicationFormPage } = await import('src/pages/hr-application-form-page')
                  return { Component: HrApplicationFormPage }
                },
                handle: {
                  crumb: () => (
                    <BreadcrumbLink to={PATHS.hr.vacations.applicationForm} name="vacations.applications.form" />
                  ),
                },
              },
              {
                path: ROUTES.hr.vacations.detail.index,
                element: <RootHrApplicationDetailPage />,
                children: [
                  {
                    path: ROUTES.hr.vacations.detail.applicationId,
                    lazy: async () => {
                      const { HrApplicationDetailPage } = await import('src/pages/hr-application-detail-page')
                      return { Component: HrApplicationDetailPage }
                    },
                    handle: {
                      // отказались от динамических хлебных крошек
                      // https://tekmates.slack.com/archives/C079LT06248/p1725869007124049?thread_ts=1725864972.701569&cid=C079LT06248
                      crumb: () => (
                        <BreadcrumbLink to={PATHS.hr.index} name="common.employee-application" end={false} />
                      ),
                    },
                  },
                ],
              },
              {
                path: ROUTES.hr.vacations.year,
                lazy: async () => {
                  const { EmployeeVacationSchedulePage } = await import('src/pages/employee-vacation-schedule-page')
                  return { Component: EmployeeVacationSchedulePage }
                },
                handle: {
                  crumb: (_data: unknown, _pathName: string, params: Params<'year'>) => (
                    <BreadcrumbLink
                      to={`${PATHS.hr.vacations.index}/${params.year}`}
                      name="vacations.vacation-schedule"
                    />
                  ),
                },
              },
              {
                path: ROUTES.hr.vacations.allVacationsSchedule.index,
                handle: {
                  crumb: () => (
                    <BreadcrumbLink
                      to={PATHS.hr.vacations.allVacationsSchedule.index}
                      name="vacations.vacation-schedule"
                    />
                  ),
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { HrAllVacationsSchedulePage } = await import('src/pages/hr-all-vacations-schedule-page')
                      return { Component: HrAllVacationsSchedulePage }
                    },
                  },
                  {
                    path: ROUTES.hr.vacations.allVacationsSchedule.year.index,
                    handle: {
                      crumb: (_data: unknown, _pathName: string, params: Params<string>) => (
                        <BreadcrumbLink
                          to={`${PATHS.hr.vacations.allVacationsSchedule.index}/${params.year}`}
                          name={params.year}
                        />
                      ),
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { HrVacationYearPage } = await import('src/pages/hr-vacation-year-page')
                          return { Component: HrVacationYearPage }
                        },
                      },
                      {
                        path: ROUTES.hr.vacations.allVacationsSchedule.year.division.index,
                        handle: {
                          crumb: (data: CompanySchema[], _pathName: string, params: Params<string>) => (
                            <BreadcrumbLink
                              to={`${PATHS.hr.vacations.allVacationsSchedule.index}/${params.year}/${params.division}`}
                              name={data?.[0]?.name ?? params.division}
                            />
                          ),
                        },
                        loader: companiesLoader(queryClient),
                        children: [
                          {
                            index: true,
                            lazy: async () => {
                              const { HrDivisionsPage } = await import('src/pages/hr-divisions-page')
                              return { Component: HrDivisionsPage }
                            },
                          },
                          {
                            path: ROUTES.hr.vacations.allVacationsSchedule.year.division.vacationScheduleId,
                            handle: {
                              crumb: (
                                data: EmployeeVacationScheduleWithChangeRecord,
                                _pathName: string,
                                params: Params<string>,
                              ) => (
                                <BreadcrumbLink
                                  to={`${PATHS.hr.vacations.allVacationsSchedule.index}/${params.year}/${params.division}/${params.vacationScheduleId}`}
                                  name={data.employeeName}
                                />
                              ),
                            },
                            lazy: async () => {
                              const { HrEmployeePage } = await import('src/pages/hr-employee-page')
                              return { Component: HrEmployeePage }
                            },
                            // выглядит, как не совсем верное решение
                            // TODO: реализовать правильно в будущем
                            loader: selectedEmployeeScheduleLoader(queryClient),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PATHS.hr.benefits.index,
            element: <Outlet />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.hr.benefits.index} name="common.benefits" />,
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { BenefitsPage } = await import('src/pages/benefits-page')
                  return { Component: BenefitsPage }
                },
              },
              {
                path: PATHS.hr.benefits.create,
                handle: {
                  crumb: (data: Benefit) => (
                    <BreadcrumbLink to={`${PATHS.hr.benefits.index}/${data.id}`} name={data.name} />
                  ),
                },
                lazy: async () => {
                  const { BenefitFormPage } = await import('src/pages/benefit-form-page')
                  return { Component: BenefitFormPage }
                },
                loader: benefitDetailLoader(queryClient),
              },
              {
                path: ROUTES.hr.benefits.management.index,
                element: <Outlet />,
                handle: {
                  crumb: () => (
                    <BreadcrumbLink to={PATHS.hr.benefits.management.index} name="benefits.benefits-management" />
                  ),
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { HrBenefitsManagementPage } = await import('src/pages/hr-benefits-management-page')
                      return { Component: HrBenefitsManagementPage }
                    },
                  },
                  {
                    path: PATHS.hr.benefits.management.create,
                    handle: {
                      crumb: () => (
                        <BreadcrumbLink to={PATHS.hr.benefits.management.create} name="benefits.new-benefit" />
                      ),
                    },
                    lazy: async () => {
                      const { HrCreateBenefitPage } = await import('src/pages/hr-create-benefit-page')
                      return { Component: HrCreateBenefitPage }
                    },
                  },
                  {
                    path: PATHS.hr.benefits.management.benefit.index,
                    handle: {
                      crumb: (data: Benefit) => (
                        <BreadcrumbLink to={`${PATHS.hr.benefits.management.index}/${data.id}`} name={data.name} />
                      ),
                    },
                    lazy: async () => {
                      const { HrBenefitEditPage } = await import('src/pages/hr-benefit-edit-page')
                      return { Component: HrBenefitEditPage }
                    },
                    loader: benefitDetailLoader(queryClient),
                  },
                ],
              },
              {
                path: ROUTES.hr.benefits.application.index,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={PATHS.hr.benefits.index} />,
                  },
                  {
                    path: PATHS.hr.benefits.application.id,
                    handle: {
                      crumb: (data: BenefitApplication, _pathName: string, params: Params<'applicationId'>) => (
                        <BreadcrumbLink
                          to={`${PATHS.hr.benefits.application.index}/${params.applicationId}`}
                          name={data.author?.name}
                        />
                      ),
                    },
                    lazy: async () => {
                      const { BenefitsApplicationPage } = await import('src/pages/benefits-application-page')
                      return { Component: BenefitsApplicationPage }
                    },
                    loader: benefitApplicationDetailLoader(queryClient),
                  },
                ],
              },
            ],
          },
          {
            path: PATHS.hr.reports.index,
            element: <HrReportsPage />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.hr.reports.index} name="common.reports" />,
            },
          },
        ],
      },

      {
        path: ROUTES.manager.index,
        element: <RoleGuard roles={[Role.Manager]} fallback={<Navigate to={PATHS.index} />} />,
        handle: {
          crumb: () => <BreadcrumbLink to={PATHS.manager.index} name="common.home" />,
        },
        children: [
          {
            index: true,
            lazy: async () => {
              const { HeadHomePage } = await import('src/pages/head-home-page')
              return { Component: HeadHomePage }
            },
          },
          {
            path: ROUTES.manager.vacations.index,
            element: <Outlet />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.manager.vacations.index} name="common.vacations" />,
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { HeadVacationsPage } = await import('src/pages/head-vacations-page')
                  return { Component: HeadVacationsPage }
                },
              },
              {
                path: ROUTES.manager.vacations.applicationForm,
                lazy: async () => {
                  const { HeadApplicationFormPage } = await import('src/pages/head-application-form-page')
                  return { Component: HeadApplicationFormPage }
                },
                handle: {
                  crumb: () => (
                    <BreadcrumbLink to={PATHS.manager.vacations.applicationForm} name="vacations.applications.form" />
                  ),
                },
              },
              {
                path: ROUTES.manager.vacations.detail.index,
                element: <RootHeadApplicationDetailPage />,
                children: [
                  {
                    path: ROUTES.manager.vacations.detail.applicationId,
                    lazy: async () => {
                      const { HeadApplicationDetailPage } = await import('src/pages/head-application-detail-page')
                      return { Component: HeadApplicationDetailPage }
                    },
                    handle: {
                      // отказались от динамических хлебных крошек
                      // https://tekmates.slack.com/archives/C079LT06248/p1725869007124049?thread_ts=1725864972.701569&cid=C079LT06248
                      crumb: () => (
                        <BreadcrumbLink to={PATHS.manager.index} name="common.employee-application" end={false} />
                      ),
                    },
                  },
                ],
              },
              {
                path: ROUTES.manager.vacations.year,
                lazy: async () => {
                  const { EmployeeVacationSchedulePage } = await import('src/pages/employee-vacation-schedule-page')
                  return { Component: EmployeeVacationSchedulePage }
                },
                handle: {
                  crumb: (_data: unknown, _pathName: string, params: Params<'year'>) => (
                    <BreadcrumbLink
                      to={`${PATHS.manager.vacations.index}/${params.year}`}
                      name="vacations.vacation-schedule"
                    />
                  ),
                },
              },
              {
                path: ROUTES.manager.vacations.allVacationsSchedule.index,
                handle: {
                  crumb: () => (
                    <BreadcrumbLink
                      to={PATHS.manager.vacations.allVacationsSchedule.index}
                      name="vacations.vacation-schedule"
                    />
                  ),
                },
                children: [
                  {
                    index: true,
                    lazy: async () => {
                      const { HeadAllVacationsSchedulePage } = await import(
                        'src/pages/head-all-vacations-schedule-page'
                      )
                      return { Component: HeadAllVacationsSchedulePage }
                    },
                  },
                  {
                    path: ROUTES.manager.vacations.allVacationsSchedule.year.index,
                    handle: {
                      crumb: (_data: unknown, _pathName: string, params: Params<string>) => (
                        <BreadcrumbLink
                          to={`${PATHS.manager.vacations.allVacationsSchedule.index}/${params.year}`}
                          name={params.year}
                        />
                      ),
                    },
                    children: [
                      {
                        index: true,
                        lazy: async () => {
                          const { HeadVacationYearPage } = await import('src/pages/head-vacation-year-page')
                          return { Component: HeadVacationYearPage }
                        },
                      },
                      {
                        path: ROUTES.manager.vacations.allVacationsSchedule.year.vacationScheduleId,
                        handle: {
                          crumb: (
                            data: EmployeeVacationScheduleWithChangeRecord,
                            _pathName: string,
                            params: Params<string>,
                          ) => (
                            <BreadcrumbLink
                              to={`${PATHS.manager.vacations.allVacationsSchedule.index}/${params.year}/${params.vacationScheduleId}`}
                              name={data.employeeName}
                            />
                          ),
                        },
                        lazy: async () => {
                          const { HeadEmployeePage } = await import('src/pages/head-employee-page')
                          return { Component: HeadEmployeePage }
                        },
                        // выглядит, как не совсем верное решение
                        // TODO: реализовать правильно в будущем
                        loader: selectedEmployeeScheduleLoader(queryClient),
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PATHS.manager.benefits.index,
            element: <Outlet />,
            handle: {
              crumb: () => <BreadcrumbLink to={PATHS.manager.benefits.index} name="common.benefits" />,
            },
            children: [
              {
                index: true,
                lazy: async () => {
                  const { BenefitsPage } = await import('src/pages/benefits-page')
                  return { Component: BenefitsPage }
                },
              },
              {
                path: PATHS.manager.benefits.create,
                handle: {
                  crumb: (data: Benefit) => (
                    <BreadcrumbLink to={`${PATHS.manager.benefits.index}/${data.id}`} name={data.name} />
                  ),
                },
                lazy: async () => {
                  const { BenefitFormPage } = await import('src/pages/benefit-form-page')
                  return { Component: BenefitFormPage }
                },
                loader: benefitDetailLoader(queryClient),
              },
              {
                path: ROUTES.manager.benefits.application.index,
                element: <Outlet />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={PATHS.manager.benefits.index} />,
                  },
                  {
                    path: PATHS.manager.benefits.application.id,
                    handle: {
                      crumb: (data: BenefitApplication, _pathName: string, params: Params<'applicationId'>) => (
                        <BreadcrumbLink
                          to={`${PATHS.manager.benefits.application.index}/${params.applicationId}`}
                          name={data.author?.name}
                        />
                      ),
                    },
                    lazy: async () => {
                      const { BenefitsApplicationPage } = await import('src/pages/benefits-application-page')
                      return { Component: BenefitsApplicationPage }
                    },
                    loader: benefitApplicationDetailLoader(queryClient),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
])
