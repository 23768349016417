export const ROUTES = {
  index: '/',
  notFound: '*',
  manager: {
    index: 'head',
    vacations: {
      index: 'vacations',
      year: ':year',
      schedule: 'schedule',
      applicationForm: 'application-form',
      detail: {
        index: 'detail',
        applicationId: ':applicationId',
      },
      allVacationsSchedule: {
        index: 'all-vacations-schedule',
        year: {
          index: ':year',
          vacationScheduleId: ':vacationScheduleId',
        },
      },
    },
    benefits: {
      index: 'benefits',
      create: ':benefitId',
      application: {
        index: 'application',
        id: ':applicationId',
      },
    },
  },

  hr: {
    index: 'hr',
    vacations: {
      index: 'vacations',
      year: ':year',
      schedule: 'schedule',
      applicationForm: 'application-form',
      detail: {
        index: 'detail',
        applicationId: ':applicationId',
      },
      allVacationsSchedule: {
        index: 'all-vacations-schedule',
        year: {
          index: ':year',
          division: {
            index: ':division',
            vacationScheduleId: ':vacationScheduleId',
          },
        },
      },
    },
    benefits: {
      index: 'benefits',
      create: ':benefitId',
      application: {
        index: 'application',
        id: ':applicationId',
      },
      management: {
        index: 'management',
        create: 'create',
        benefit: {
          index: ':benefitId',
        },
      },
    },
    reports: {
      index: 'reports',
    },
  },

  employee: {
    index: 'employee',
    benefits: {
      index: 'benefits',
      create: ':benefitId',
      application: {
        index: 'application',
        id: ':applicationId',
      },
    },
    vacations: {
      index: 'vacations',
      year: ':year',
      applicationForm: 'application-form',
      detail: {
        index: 'detail',
        applicationId: ':applicationId',
      },
    },
  },
}
