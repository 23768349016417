import { Breadcrumbs as BreadcrumbsMantine } from '@mantine/core'
import { Fragment } from 'react'
import { Params, UIMatch, useMatches } from 'react-router-dom'

import { Icon } from 'src/shared/ui-kit/icon'

type IMatches = UIMatch<
  unknown,
  { crumb: (data: unknown, pathname: string, params: Required<Params<string>>) => React.ReactNode }
>

export const Breadcrumbs = () => {
  const matches = useMatches() as IMatches[]

  const crumbs = matches
    .filter((match) => !!match.handle?.crumb)
    .map((match) => match.handle.crumb(match.data, match.pathname, match.params))

  return (
    <BreadcrumbsMantine separator={<Icon name="arrow-right" size={16} />} style={{ flexWrap: 'wrap', rowGap: 4 }}>
      {crumbs.map((crumb, i) => (
        <Fragment key={i}>{crumb}</Fragment>
      ))}
    </BreadcrumbsMantine>
  )
}
